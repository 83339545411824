import React from "react";
import Widget from "../../../components/Widget";
import { Avatar } from "antd";
import Table from "../../../components/Table";
import moment from "moment";
import { getLocalDate, getLocalTime, getTimeSlot } from "../../../utils/CommonFunctions";
import { useNavigate } from "react-router-dom";

const TablesData = () => {
  const navigate = useNavigate();

  let column1 = [
    {
      title: "Id",
      render: (record) => <span>{record?.orderId}</span>,
      key: "id",
    },

    {
      title: "Items",
      render: (record) => (
        <span>
          {record?.products[0]?.productName} {record?.products.length > 1 ? `+${record?.products.length - 1} more` : ""}
        </span>
      ),
      key: "items",
    },
    {
      title: "Total Price",
      render: (record) => <span>${record?.totalPrice}</span>,
      key: "price",
    },
    {
      title: "Date",
      render: (record) => <span>{getLocalDate(record?.createdAt)}</span>,
      key: "date",
    },
  ];
  let column2 = [
    {
      title: "Id",
      render: (record) => <span>{record.bookingId}</span>,
      key: "",
    },
    {
      title: "Service Name",
      render: (record) => (
        <div className="flex items-center gap-2">
          <span>{record?.serviceName || "---"}</span>
        </div>
      ),
      key: "serviceName",
    },

    {
      title: "Total Price",
      render: (record) => <span>${record?.price}</span>,
      key: "totalPrice",
    },
    {
      title: "Date",
      render: (record) => <span>{getLocalDate(record.selectedDate)}</span>,
      key: "date",
    },
    {
      title: "Time Slot",
      render: (record) => {
        if (record?.ServiceType === "customRequest") {
          return (
            <span>
              {getLocalTime(record?.timeSlot)}-{getLocalTime(record?.time2Slot)}
            </span>
          );
        } else {
          return <span>{getTimeSlot(record?.timeSlot, record?.duration)}</span>;
        }
      },
      key: "time",
    },
  ];
  return (
    <div className="mt-7">
      <Widget className="p-3 mb-7">
        <div className="flex flex-row justify-between">
          <h4 className="font-roboto-bold mb-2">Recent Sales</h4>
          <span onClick={() => navigate("/orders")} className="text-[#1F75FE] cursor-pointer">
            View All
          </span>
        </div>
        <Table
          pagination={false}
          scroll={{ x: 600 }}
          rowClassName="custome-padding-row"
          className="custome-dashboard-table"
          columns={column1}
          apiName="getRecentSale"
          recordName="recentSale"
        />
      </Widget>
      <Widget className="p-3">
        <div className="flex flex-row justify-between">
          <h4 className="font-roboto-bold mb-2">Recent Booking</h4>
          <span onClick={() => navigate("/booking")} className="text-[#1F75FE] cursor-pointer">
            View All
          </span>
        </div>
        <Table
          className="custome-dashboard-table"
          rowClassName="custome-padding-row"
          pagination={false}
          scroll={{ x: 600 }}
          columns={column2}
          apiName="getRecentBooking"
          recordName="recentBooking"
        />
      </Widget>
    </div>
  );
};

export default TablesData;
